import React from "react";
import "./OfficialWeb.scss";
import Img from "../../assets/phone3.webp";

const OfficialWeb = () => {
  return (
    <div className="official-main">
      <img src={Img} alt="" />
      <div className="official-cont">
        <h2>
          <span>
            Official website of the
            <span className="pink-text"> Sweet Bonanza </span>
            slot - your path to sweet success and wealth
          </span>
        </h2>
        <p>
          On the official website of the{" "}
          <span className="pink-text">Sweet Bonanza</span> slot , we open the
          doors to a world of sweet opportunities, where excitement and winnings
          lead to riches. We are proud that our{" "}
          <span className="pink-text">Sweet Bonanza</span> site is official and
          provides you with a safe gaming space. Here you will find a wide
          selection of exciting slots, including{" "}
          <span className="pink-text">Sweet Bonanza</span> , which bring
          incredible excitement and the chance to hit the jackpot. Join our
          community of gamblers and start your path to sweet success and
          financial well-being on the official website of the{" "}
          <span className="pink-text">Sweet Bonanza</span> slot !
        </p>
        <h2>
          <span className="pink-text">Sweet Bonanza</span> official website -
          the place where gambling legends are born
        </h2>
        <p>
        <span className="pink-text">Sweet Bonanza</span> official website is not just a platform for playing, it
          is a place where gambling legends are born and incredible winnings are
          made. Here you will find the best slots, including <span className="pink-text">Sweet Bonanza</span> , and
          the chance to hit the jackpot that will change your life. We guarantee
          reliability and fairness in the game, providing you with a unique
          gambling experience. Join the world of <span className="pink-text">Sweet Bonanza</span> today and become
          part of our gambling legend!
        </p>
        <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button>Where to play?</button>
        </a>
      </div>
    </div>
  );
};

export default OfficialWeb;
