import React from "react";
import "./OftenSearched.scss";
const OftenSearched = () => {
  const data = [
    { text: "Official website Sweet Bonanza -" },
    { text: " Candyland Sweet Bonanza" },
    { text: "- Casino Sweet Bonanza" },
    { text: "- Demo in rubles Sweet Bonanza " },
    { text: "- Demo Sweet Bonanza " },
    { text: "- Dice Demo Sweet Bonanza " },
    { text: "- Game Sweet Bonanza" },
    { text: "- Play for money Sweet Bonanza" },
    { text: "- Play with a bonus in Sweet Bonanza " },
    { text: "- Slot machine Sweet Bonanza " },
    { text: "- Live Casino Sweet Bonanza " },
    { text: "- Max Win Sweet Bonanza" },
    { text: "- Mobile application Sweet Bonanza" },
    { text: "- Reviews of the game Sweet Bonanza" },
    { text: "- Pragmatic Play Sweet Bonanza" },
    { text: "- Download for android Sweet Bonanza" },
    { text: "- Download for phone Sweet Bonanza" },
    { text: "- Slot Sweet Bonanza" },
    { text: "- Statistics Sweet Bonanza" },
    { text: "- Xmas play Sweet Bonanza" },
  ];
  return (
    <div className="often ">
      <div className="often-cont center">
        <h2>OftenSearched</h2>

        <div>
          {data.map((item, index) => (
            <a href="https://catchthecatkz.com/d467ed7a5" target="_blank" key={index}>
              {item.text} 
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OftenSearched;
