import React, { useEffect, useState } from 'react'
import "./LastWins.scss"

const LastWins = () => {
    
    const initialLogs = ["", "", ""];
  const [log, setLog] = useState(initialLogs);
  const casinos = ["Monro", "CatCasino", "Daddy", "Gamma"];
  const nickNames = [
    "Fill D.",
    "Thunder Zoo",
    "Petr 17",
    "Full H",
    "Zoya13",
    "Postmod3",
    "Kill time",
    "Bro Bred",
    "Soft Bill",
  ];
  const currencies = ["$"];
  const icons = ["💎", "💰", "💸"];

  const getRandomElement = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  const generateRandomLog = () => {
    const currentDate = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
    const casino = getRandomElement(casinos);
    const nickname = getRandomElement(nickNames);
    const slot = "Sweet Bonanza"; // You can change this to any desired slot game
    const maxWinning = 21000;
    const winning = Math.floor(Math.random() * (maxWinning + 1)); // Generating random winning amount up to $21,000
    const currency = getRandomElement(currencies);
    const icon = getRandomElement(icons);

    const newLog = `${currentDate}, Casino: ${casino}, Nickname: ${nickname}, Slot: ${slot}, Winning: ${winning}${currency} ${icon}`;

    // Randomly assign the log to one of the three positions
    const randomPosition = Math.floor(Math.random() * 3);
    setLog((prevLogs) => {
      const newLogs = [...prevLogs];
      newLogs[randomPosition] = newLog;
      return newLogs;
    });
  };

  useEffect(() => {
    // Initial log
    generateRandomLog();
    generateRandomLog();
    generateRandomLog();

    // Set up interval to update log every 5 seconds
    const intervalId = setInterval(() => {
      generateRandomLog();
    }, 3500);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className="background">
        <div className="random-wins">
            <h2>
                <span className="white-text">Latest winnings with promo code </span>
                <span className="pink-text">GGPLAY2023 </span>
            </h2>
            <div className="random-cont">
                <div className="random-msg">{log[0]}</div>
                <div className="random-msg">{log[1]}</div>
                <div className="random-msg">{log[2]}</div>
            </div>
        </div>
    </div>
  )
}

export default LastWins