import React from "react";
import "./HowItWorks.scss";
import phone from "../../assets/phone.webp";
import { Link } from "react-scroll";

const HowItWorks = () => {
  return (
    <div className="how">
      <div className="how-cont center">
        <div className="how-cont-left">
          <img src={phone} alt="" />
        </div>
        <div className="how-cont-right">
          <h2>
            Official website of the <span>Sweet Bonanza</span> slot - your path
            to sweet victories
          </h2>
          <p>
            Welcome to the official website of the <span>Sweet Bonanza</span>
            slot , where every spin brings sweet moments of excitement and a
            unique opportunity to win big prizes. We are proud to present to you
            our official <span>Sweet Bonanza</span>website , which has become
            home to many gamblers. Here you will find a wide selection of
            exciting slots, including <span>Sweet Bonanza</span> , and the
            chance to hit the jackpot. Our Sweet Bonanza site is safe and
            secure, allowing you to enjoy the game with complete confidence.
            Join us right now and start your journey to sweet victories on the
            official website of the <span>Sweet Bonanza</span> slot !
          </p>

          <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button>Where to play?</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
