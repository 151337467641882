import React from "react";
import "./WhereToPlay.scss";
import win1 from "../../assets/1win.svg";
import daddy from "../../assets/daddy.svg";
import gamma from "../../assets/gamma.png";
import vavada from "../../assets/vavada.png";
import cat from "../../assets/cat.svg";
import kent from "../../assets/kent.svg";
import star from "../../assets/rating-star.svg";
import izzi from "../../assets/izzi.png";
import pinup from "../../assets/pinup.webp";

const WhereToPlay = () => {
  const card = [
    {
      img: cat,
      title: "CatCasino",
      ratingImg: star,
      bonus1: `150FS + 325% bonus`,
      link: "https://catchthecatkz.com/d467ed7a5",
      sticker: "December Players' Choice",
      button: "GO",
    },
    {
      img: gamma,
      title: "GammaCasino",
      ratingImg: star,
      bonus1: `Bonus 500%`,
      link: "https://colorful-road-three.com/d35db545f",
      sticker: "New 2023",
      button: "GO",
    },
    {
      img: vavada,
      title: "VavadaCasino",
      ratingImg: star,
      bonus1: `200FS + 395% bonus`,
      link: "https://vavadapartners.pro/?promo=9e1e1391-392b-4e33-87c7-a3523df5e8cf&target=register",
      sticker: "Profitable bonuses",
      button: "GO",
    },
    {
      img: win1,
      title: "1win",
      ratingImg: star,
      bonus1: `Bonus 500%`,
      link: "https://1wauah.xyz/#79x5",
      class: "none",
      button: "GO",
    },
    {
      img: kent,
      title: "KentCasino",
      ratingImg: star,
      bonus1: `200FS + 395% bonus`,
      class: "none",
      link: "https://passage-through-trees.com/d753a0bcb",
      button: "GO",
    },
    {
      img: daddy,
      title: "DaddyCasino",
      ratingImg: star,
      bonus1: `150FS + 325% bonus`,
      class: "none",
      link: "https://nice-road-two.com/d74076b1c",
      button: "GO",
    },
    {
      img: pinup,
      title: "PinUpCasino",
      ratingImg: star,
      bonus1: `200FS + 395% bonus`,
      class: "none",
      link: "https://onlinepuonline.com/cwbBKAX7/",
      button: "GO",
    },
    {
      img: izzi,
      title: "IzziCasino",
      ratingImg: star,
      bonus1: `Bonus 500%`,
      class: "none",
      link: "https://izzicasino.life/cf91e09c3",
      button: "GO",
    },
  ];

  return (
    <div className="where">
      <div className="where-cont center">
        <h2>
          Where to play <span>Sweet Bonanza</span>
        </h2>
        <div className="where-cont-cards">
          {card.map((item, index) => (
            <div className="where-cont-cards-single" key={index}>
              <div className={` where-cont-cards-single-sticker ${item.class}`}>
                {item.sticker}
              </div>
              <img src={item.img} alt={item.img} />
              <p>{item.title}</p>
              <div className="where-cont-cards-single-rating">
                <img src={item.ratingImg} alt="" />
                <p>5.0</p>
              </div>
              <p>{item.bonus1}</p>

              <p>{item.bonus2}</p>
              <a href={item.link} target="_blank">
                <button>{item.button}</button>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhereToPlay;
