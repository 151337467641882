import React from "react";
import "./SweetPath.scss";
import Img from "../../assets/phone5.webp";

const SweetPath = () => {
  return (
    <div className="path-main center">
      <img src={Img} alt="" />
      <div className="path-cont">
        <h2>
          <span className="pink-text">Sweet Bonanza</span> - your path to
          boundless excitement and sweet success
        </h2>
        <p>
          <span className="pink-text">Sweet Bonanza</span> official website is
          your guide to the world of boundless excitement and sweet success. On
          our site you will find a wide selection of exciting games, including
          <span className="pink-text">Sweet Bonanza</span> , and the opportunity
          to win big prizes. We guarantee the reliability and safety of your
          game so that you can focus on the gambling moments. Join our gambling
          community and start your path to limitless excitement and sweet
          success on the official website of the <span className="pink-text">Sweet Bonanza</span> slot !
        </p>
        <h2>
          <span className="pink-text">Sweet Bonanza</span> - your personal
          ticket to sweet dreams and financial independence
        </h2>
        <p>
        <span className="pink-text">Sweet Bonanza</span> official website is not just a gaming platform, it is
          your personal ticket to sweet dreams and financial independence. Here,
          every spin brings you a chance to win and change your life. We're
          proud of our exciting games, including the <span className="pink-text">Sweet Bonanza</span> slots , which
          give you the chance to hit the big jackpot. Join our gambling
          community and start your journey to sweet dreams and financial
          independence on the official website of the <span className="pink-text">Sweet Bonanza</span> slot !
        </p>
        <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button>Where to play?</button>
        </a>
      </div>
    </div>
  );
};

export default SweetPath;
