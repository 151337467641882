import React, { useEffect, useState } from "react";
import "./Footer.scss";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer-container center">
        <p>
          real-sweet-bonanza.top © {currentYear} Sweet Bonanza Official Website
        </p>
        <p>
          The information provided by sweetbonanza-official (“Company”, “we”, “our”,
          “us”) about Sweet Bonanza (the “Site”) is for general informational
          purposes only. All information on the Site is provided in good faith,
          but we make no representations or warranties of any kind, express or
          implied, regarding the accuracy, adequacy, validity, reliability,
          availability or completeness of any information on the Site. Under no
          circumstances will we be liable to you for any loss or damage of any
          kind incurred as a result of using the site or relying on any
          information provided on the site. Your use of the site and reliance on
          any information on the site is entirely at your own risk.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
