import React from "react";
import "./Guarante.scss";
import Img from "../../assets/img-2.webp"


const Guarante = () => {
  return (
    <div className="background guarant">
      <div className="guarant-cont">
        <div className="guarant-text">
          <h2>
            <span className="pink-text">Sweet Bonanza</span>
            <span className="white-text">
              official website - your guarantee of reliability and big winnings
            </span>
          </h2>
          <p>
            <span className="white-text">
              <span className="pink-text">Sweet Bonanza</span> official website is your reliable partner in the
              world of excitement and big winnings. We're proud that our <span className="pink-text">Sweet
              Bonanza</span> site is official and secure, giving you the opportunity to
              enjoy the game and win big prizes with confidence. We offer a wide
              selection of exciting games, including <span className="pink-text">Sweet Bonanza slots</span> , which
              bring unforgettable moments of excitement. Our games are created
              with love for detail and give you the chance to hit the jackpot.
              Join us right now and start your journey to sweet victories on the
              official website of the <span className="pink-text">Sweet Bonanza</span> slot !
            </span>
          </p>
          <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button>Where to play?</button>
            </a>
        </div>
        <img src={Img} alt="alt" />
      </div>
    </div>
  );
};

export default Guarante;
