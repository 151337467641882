import React from "react";
import "./SweetMotiv.scss";
import Img from "../../assets/phone4.webp"

const SweetMotiv = () => {
  return (
    <div className="motiv-bg">
      <div className="motiv-main center">
        <div className="motiv-cont">
          <h2>
            The official website of the
            <span className="pink-text"> Sweet Bonanza </span>
            slot is the place where your path to sweet winnings and excitement
            begins
          </h2>
          <p>
            On the official website of the{" "}
            <span className="pink-text">Sweet Bonanza</span> slot , we invite
            you to start your journey into the world of excitement and sweet
            winnings. Here at our online casino you will find an endless variety
            of exciting slot machines, including Sweet Bonanza , and the
            opportunity to win one of them.{" "}
            <span className="pink-text">Sweet Bonanza</span> official website
            guarantees your game reliability and safety, and every spin brings
            unforgettable emotions. Join our gambling team and start your
            journey to sweet winnings and exciting excitement on the official
            website of the <span className="pink-text">Sweet Bonanza</span> slot
            !
          </p>
          <h2>
            <span className="pink-text">Sweet Bonanza</span> official website -
            your key to the world of sweet adventures and drive
          </h2>
          <p>
          <span className="pink-text">Sweet Bonanza</span> official website is not just an online casino, it is
            your key to the world of sweet adventures and drive. Here, every
            player has the opportunity to experience real excitement and win big
            prizes, including jackpots. Our  <span className="pink-text">Sweet Bonanza</span> site ensures reliable
            and fair gaming, allowing you to enjoy the excitement with complete
            confidence. Join our gambling family and start your journey into the
            world of sweet adventures and excitement on the official website of
            the  <span className="pink-text">Sweet Bonanza</span> slot !
          </p>
          <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <button>Where to play?</button>
        </a>
        </div>
        <img className="shadow" src={Img} alt="" />
      </div>
    </div>
  );
};

export default SweetMotiv;
