import React, { useState } from "react";
import "./Header.scss";
import logo from "../../assets/header-logo.webp";
import lolypop from "../../assets/main-img.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header>
      <div className="hero ">
        <div className="header-container center">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
          <nav>
            <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className={menuOpen ? "open" : ""}>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">WHERE TO PLAY </a>
              </li>
              <li>
                <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">DEMO </a>
              </li>
              <li>
                <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">CASINO </a>
              </li>
              <li>
                <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">DOWNLOAD</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="hero-content center">
          <div className="hero-content-left">
            <h1>
              <span>Sweet Bonanza</span> Official Website
            </h1>
            <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
              <button>Where to play?</button>
            </a>
          </div>
          <div className="hero-content-right">
            <img src={lolypop} alt="" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
