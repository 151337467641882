import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import HowItWorks from "./components/howItWorks/HowItWorks";
import WhereToPlay from "./components/whereToPlay/WhereToPlay";
import LastWins from "./components/lastWins/LastWins";
import Guarante from "./components/guarante/Guarante";
import OfficialWeb from "./components/officialWeb/OfficialWeb";
import SweetMotiv from "./components/sweetMotiv/SweetMotiv";
import SweetPath from "./components/sweetPath/SweetPath";
import OftenSearched from "./components/oftenSearched/OftenSearched";

function App() {
  return (
    <>
      <Header />
      <div className="app">
        <HowItWorks />
        <WhereToPlay />
        <LastWins />
        <Guarante />
        <OfficialWeb />
        <SweetMotiv />
        <SweetPath />
        <WhereToPlay />
        <OftenSearched />
      </div>
      <Footer />
    </>
  );
}

export default App;
